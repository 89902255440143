<template>
  <div class="card p-0 m-0">
    <div class="card-body p-0 m-0">
      <h4 class="card-title ms-2 mt-2">Factuurdetailpagina</h4>
      <div class="row p-0 ms-0 me-2">
        <div class="col-12 p-1 m-0 d-flex justify-content-between">
          <h3>{{ invoice.customer?.name }}{{ getHeader(invoice.invoice_number) }}</h3><button v-if="invoice.status?.id != 1 && invoice.status?.id != 3" type="button" @click="resentInvoice" class="btn btn-primary">Resent</button>
        </div>
      </div>
      <div class="row p-0 m-0">
        <div class="col-lg-3 col-md-4 mt-2 mb-2">
          <div class="d-flex align-items-center">
            <i class="bi bi-circle-fill me-2" :class="getColor(invoice.status?.id)"></i>
            <select v-model="invoice.status.id" @change="updateInvoiceStatus" class="form-select" :disabled="isStatusLocked">
              <option v-for="status in invoiceStatussen" :key="status.id" :value="status.id" :disabled="isStatusDisabled(status.id)">
                {{ status.label }}
              </option>
            </select>
          </div>
          <div>{{ invoice.error_message }}</div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="property-list">
              <div class="card mb-2">
                <div class="card-body">
                  <h5 class="card-title">BillTo</h5>
                  <!-- <h6 class="card-subtitle mb-2 text-body-secondary"><b>role: billTo</b></h6> -->
                  <div class="row custom-row ms-0 me-0">
                    <div class="col-auto">
                      <label for="productCode" class="col-form-label">addressId</label>
                      <EditInvoiceProp id="productCode" :type="'text'" :classStr="'text-end'" :styleStr="'max-width: 12em;'" :rowIndex="index"
                        :property="billTo" @changed="billToPropChanged" />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-4">
          <div class="property-list">
              <div class="card mb-2">
                <div class="card-body">
                  <h5 class="card-title">ShipTo</h5>
                  <!-- <h6 class="card-subtitle mb-2 text-body-secondary"><b>role: shipTo</b></h6> -->
                  <div class="row custom-row ms-0 me-0">
                    <div class="col-auto">
                      <label for="productCode" class="col-form-label">addressId</label>
                      <EditInvoiceProp id="productCode" :type="'text'" :classStr="'text-end'" :styleStr="'max-width: 12em;'" :rowIndex="index"
                        :property="shipTo" @changed="shipToPropChanged" />
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div class="row mb-3 ms-2 me-3 px-0">
        <div class="col-lg-3 col-md-4 mt-2">
          <h5>Output Unit4</h5>
        </div>
        <div class="col-lg-9 col-md-8 mb-3 py-2" >
          <InvoiceDetailTab @click="select" ></InvoiceDetailTab>
          <div class="border no-top-border p-3 custom-border-radius">
            <json-tree v-if="selectedView=='object'" :data="invoice.xml_object"></json-tree>
            <div v-if="selectedView=='xml'">
              <pre>{{ invoice.output }}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceFactory from "../services/ServiceFactory"
import Utils from "../utils/Utils"
import EditInvoiceProp from "../components/EditInvoiceProp.vue"
import JsonTree from '../components/JsonTree.vue'
import InvoiceDetailTab from "@/components/InvoiceDetailTab.vue"

export default {
  name: 'InvoiceDetailPage',
  props: ['id'],
  emits: ['showToast'],
  components: { EditInvoiceProp, JsonTree, InvoiceDetailTab },
  data() {
    return {
      invoiceService: {},
      invoice: {status: {id: 1}},
      billToObj: {},
      billTo: {},
      shipToObj: {},
      shipTo: {},
      invoiceproperties: [],
      selectedInvoiceAddress: '',
      selectedView: 'object',
      treeOptions: {
        maxDepth: 4,
        rootObjectKey: "root",
        modifiable: false,
        link: false,
        limitRenderDepth: false
      },
      invoiceStatussen: [],
      originalStatusId: null
    }
  },
  computed: {
    isStatusLocked() {
      return this.invoice.status?.id === 1 || this.invoice.status?.id === 3; // 1 voor Automatisch verwerkt, 3 voor Handmatig verwerkt
    }
  },
  methods: {
    getColor(status) {
      return Utils.getColorForInvoiceStatus(status)
    },
    selectInvoiceAddress() {
      if (!this.selectedInvoiceAddress) {
        return
      }
      console.log('selectedInvoiceAddress: ', this.selectedInvoiceAddress)
    },
    async loadInvoiceDetail() {
      this.invoiceService = ServiceFactory.getService('InvoiceService')
      this.invoice = await this.invoiceService.getInvoice(this.id)
      this.invoiceStatussen = await this.invoiceService.getInvoiceStatussen()
      console.log('invoice:', this.invoice);
      this.billToObj = this.invoice.xml_object?.cXML?.Request?.InvoiceDetailRequest?.InvoiceDetailRequestHeader?.InvoicePartner?.find(a => a.Contact['@role'] === 'billTo')
      console.log('cXML:', this.invoice.xml_object?.cXML)
      this.billTo = this.billToObj.Contact['@addressID']
      this.shipToObj = this.invoice.xml_object?.cXML?.Request?.InvoiceDetailRequest?.InvoiceDetailRequestHeader?.InvoiceDetailShipping?.Contact?.find(a => a['@role'] === 'shipTo')
      this.shipTo = this.shipToObj['@addressID']
      this.originalStatusId = this.invoice.status?.id;
    },
    async resentInvoice() {
      const result = await this.invoiceService.retryInvoice(this.invoice)
      if(result.success) {
        this.$router.push('/invoices')
      } else {
        this.loadInvoiceDetail()
        this.$emit('showToast', [`Er is een fout opgetreden bij het opnieuw versturen van de factuur: ${result.message}`, 'bg-danger'])
      }
      console.log('result:', result) 
    },
    getHeader(txt) {
      if (txt && txt !== '') {
        return " - " + txt
      }
      return ''
    },
    getAddressCode(addressCode) {
      return addressCode ? addressCode : 'onbekend'
    },
    addressCodeChanged(value, index) {
      console.log(`addressCodeChanged: (${value}, ${index})`)
      this.invoice.xml_object.lines[index].deliveryAddress.id = value
    },
    billToPropChanged(value) {
      this.billTo = value
      this.billToObj.Contact['@addressID'] = this.billTo
    },
    shipToPropChanged(value) {
      this.shipTo = value
      this.shipToObj['@addressID'] = this.shipTo
    },
    select(view) {
      this.selectedView = view
    },
    isStatusDisabled(statusId) {
      return statusId === 1 || statusId === 3;
    },
    async updateInvoiceStatus() {
      if (this.isStatusLocked || this.isStatusDisabled(this.invoice.status.id)) {
        this.$emit('showToast', ['Deze factuurstatus kan niet worden gewijzigd', 'bg-warning']);
        // Reset de status naar de oorspronkelijke waarde
        this.invoice.status.id = this.originalStatusId;
        return;
      }
      try {
        const result = await this.invoiceService.updateInvoiceStatus(this.invoice.id, this.invoice.status.id);
        if (result.success) {
          this.$emit('showToast', ['Factuurstatus succesvol bijgewerkt', 'bg-success']);
          this.originalStatusId = this.invoice.status.id; // Update de originele status
        } else {
          this.$emit('showToast', [`Fout bij het bijwerken van de factuurstatus: ${result.message}`, 'bg-danger']);
          // Reset de status naar de oorspronkelijke waarde
          this.invoice.status.id = this.originalStatusId;
        }
      } catch (error) {
        console.error('Fout bij het updaten van de factuurstatus:', error);
        this.$emit('showToast', ['Er is een fout opgetreden bij het bijwerken van de factuurstatus', 'bg-danger']);
        // Reset de status naar de oorspronkelijke waarde
        this.invoice.status.id = this.originalStatusId;
      }
    }
  },
  mounted() {
    this.loadInvoiceDetail()
  }
}
</script>

<style scoped>
.custom-border-radius {
  border-radius: 0 0 7px 7px;
}
.no-top-border {
  border-top: none !important;
}
.property-list {
  display: flex;
  flex-direction: column;
}
.invoiceline-item {
  padding: 0.5em 0;
}
.custom-row {
  background-color: #f8f9fa; /* Licht grijze achtergrondkleur */
  border-radius: 8px; /* Afgeronde hoeken */
  padding: 1em;
  margin-bottom: 1em;
}
.invoiceline-item .row {
  margin-bottom: 0.5em;
}
@media (max-width: 767px) {
  .invoiceline-item .row > div {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
/* Voeg deze stijl toe om de uitgeschakelde opties visueel te onderscheiden */
select option:disabled {
  color: #999;
  font-style: italic;
}
</style>